.prerender_top_job_card {
  --green: #129015;
  --dark-gray: #424242;

  background-color: white;
  display: grid;
  padding: 25px 24px 36px;
  opacity: 1;
  transition: all .4s;
  transition-timing-function: ease;
  border-left: solid 3px var(--green);
  margin-bottom: 40px;
  grid-template-areas: "title title title"
    "company company company"
    "likesmatchtags likesmatchtags likesmatchtags"
    "range range button";
  position: relative;
  max-height: 1600px;
}

.prerender_top_job_card h3,
.prerender_top_job_card span {
  transition: all .4s;
  max-height: 100px;
  transition-timing-function: ease;
}

.prerender_top_job_title {
  grid-area: title;
  position: relative;
  margin-bottom: 0 !important;
}

.prerender_top_job_title img {
  display: none;
}

.prerender_company {
  display: flex;
  grid-area: company;
  flex-direction: column;
  margin-bottom: 11px;
}

.prerender_top_job_org,
.prerender_top_job_salary {
  font-size: 14px;
  line-height: 21px;
  color: var(--dark-gray);
  font-weight: 700;
}

.prerender_likes_match_tags {
  grid-area: likesmatchtags;
  display: flex;
  flex-direction: column;
}

.prerender_match_text {
  position: relative;
  bottom: 2px;
  padding-left: 18px;
}

.prerender_match_text:last-child {
  padding-left: 5px;
  padding-right: 22px;
}

.prerender_tags {
  display: block;
  padding: 20px 0 0;
  margin-bottom: -3px;

}


.prerender_tags div {
  position: relative;
  top: -3px;
  margin-right: 8px;
}

.prerender_tags div span {
  white-space: nowrap;
}

.prerender_button {
  grid-area: button;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  top: 11px;
}

.prerender_range {
  grid-area: range;
  display: flex;
  position: relative;
  top: 7px;
  flex-direction: column;
}

.prerender_range span {
  text-align: right;
}

.prerender_arrow_right {
  width: 15px;
  position: relative;
  top: 1px;
}

@media only screen and (max-width: 767px) {
  .prerender_tags div {
    margin-bottom: 8px;
  }

  .prerender_top_job_apply {
    max-width: 134px;
    height: fit-content !important;
  }

  .prerender_range span {
    text-align: left;
  }
}

@media only screen and (min-width: 768px) {
  .prerender_top_job_card {
    padding: 24px 24px 21px;
    grid-template-areas: "title title button"
      "company company range"
      "likesmatchtags likesmatchtags range";
  }

  .prerender_likes_match_tags {
    flex-direction: row;
    align-items: center;
  }

  .prerender_tags {
    margin-bottom: 0;
    padding: 10px 0 0 12px;
  }

  .prerender_button {
    align-items: flex-start;
    top: 0;
  }

  .prerender_range_label {
    padding-left: 6px;
    padding-top: 5px;
  }
}