@import './vars.module.scss';

.surveyContainer {
  background-color: white;
  padding: 20px;
  border: solid 1px #e0e0e0;
}
.intro {
  font-size: 15px;
  color: $dark-gray;
  font-weight: 600;
  color: $black;
}
.questionTitle {
  font-size: 15px;
  color: $dark-gray;
}
.bellIcon__container {
  position: fixed;
  bottom: 58px;
  right: 19px;
}