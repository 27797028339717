@import './vars.module.scss';

.content {
  padding: 30px 0;
}
.titleFilters {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
.bottomBar {
  padding-top: 16px;
  padding-bottom: 19px;
  display: flex;
  justify-content: space-between;
}
.backToTop {
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  color: $dark-gray;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.backToTopImg {
  width: 34px;
  height: 34px;
  margin-left: 7px;
}
.backToTopPlaceholder {
  @media screen and (max-width: 599px) {
    display: none;
  }
}
.careersFiltersContainer {
  position: relative;
  padding-top: 10px;
  padding-bottom: 14px;
  div:not(:first-of-type) {
    margin-top: 16px;
  }
  @media screen and (min-width: 768px) {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding-top: 0;
    padding-bottom: 0;
    top: -13px;
    div:not(:first-of-type) {
      margin-left: 10px;
      margin-top: 0;
    }
  }
}
.autocompleteMultipleContainer {
  & > div:last-child {
    margin-top: 16px;
  }
  @media screen and (min-width: 768px) {
    grid-gap: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    & > div:last-child {
      margin-top: 0;
    }
  }
}
.stepperContainer {
  position: relative;
  padding-top: 10px;
  padding-bottom: 14px;
  @media screen and (min-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
    top: -13px;
  }
}
.printShareToolbar__disclaimer {
  max-width: 500px;
  @media screen and (max-width: 767px) {
    margin-bottom: 20px;
  }
  @media screen and (min-width: 1024px) {
    max-width: 840px;
  }
}
.educationForm {
  max-width: 600px;
}
.experienceResume {
  grid-gap: 50px;
  display: grid;
  grid-template-columns: 1fr;
  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
}
.experienceRightSide {
  padding-left: 0;
  @media screen and (min-width: 768px) {
    border-left: 1px solid $medium-gray;
    padding-left: 50px;
  }
}
