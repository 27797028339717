@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Roboto:wght@500&family=Source+Serif+Pro:wght@400;700&display=swap');
@import './vars.module.scss';

body {
  font-family: Avenir, Arial, 'Helvetica Neue', Helvetica, sans-serif;
  background-color: $white;
  min-height: 100vh;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}
.bouncing-loader > div:nth-child(4) {
  animation-delay: 0.6s;
}
.autocomplete-paper-border {
  border: solid 1px rgba(0, 0, 0, 0.2);
}
