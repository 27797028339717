$organization-name: 'State of Arkansas';
$footer-email: 'dis.callcenter@arkansas.gov';
$logo-header-url: '/images/logo-header-hi.jpeg';
$logo-header-height: 30px;
$logo-footer-url: '/images/logo-footer-hi.webp';
$logo-footer-height: 80px;
$font-family: Avenir, Arial, sans-serif;
$primary-color-main: #297db0;
$primary-color-dark: darken($primary-color-main, 5%);
$navy: #132741;
$navy-transparent: rgba(62, 78, 99, 60);
$accent-color-main: #eb9c0a;
$secondary-color-main: #007041;
$secondary-color-dark: darken($secondary-color-main, 5%);
$error-color-main: #c10230;
$background-color-default: #efefef;
$background-color-paper: #fff;
$dark-gray: #424242;
$mid-light-gray: #dbdbdb;
$medium-gray: #b7b7b7;
$light-gray: rgb(248, 249, 250);
$black: rgb(32, 33, 36);
$white: #fff;
$red: #c10230;
$dark-red: #cb3130;
$light-red: #fce6e6;
$green: #007041;
$dark-green: #002910;
$light-green: #c9e4d9;
$progress-green: #007041;
$orange: #e2970a;
$dark-orange: darken($orange, 5%);
$blue: #297db0;
$dark-blue: darken($blue, 5%);
$action-blue: #2d7ca9;
$action-blue-hover: darken($action-blue, 5%);
$tag-skill: $action-blue;
$filter-tag: $white;
$filter-tag-hover: darken($white, 4%);

//mui breakpoints
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-md: 960px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1920px;

$state-seal-url: '/images/logo-ar.png';
$state-seal-href: 'https://dws.arkansas.gov/';
$header-logo-href: 'https://dws.arkansas.gov/';

:export {
  organizationName: $organization-name;
  footerEmail: $footer-email;
  logoHeaderUrl: $logo-header-url;
  logoFooterUrl: $logo-footer-url;
  fontFamily: $font-family;
  primaryColorMain: $primary-color-main;
  primaryColorlight: lighten($primary-color-main, 10%);
  primaryColorDark: $primary-color-dark;
  secondaryColorMain: $secondary-color-main;
  secondaryColorDark: $secondary-color-dark;
  accentColorMain: $accent-color-main;
  errorColorMain: $error-color-main;
  backgroundColorDefault: $background-color-default;
  backgroundColorPaper: $background-color-paper;
  mediumGray: $medium-gray;
  midLightGray: $mid-light-gray;
  lightGray: $light-gray;
  black: $black;
  white: $white;
  navy: $navy;
  navyTransparent: $navy-transparent;
  red: $red;
  darkRed: $dark-red;
  lightRed: $light-red;
  green: $green;
  lightGreen: $light-green;
  darkGreen: $dark-green;
  progressGreen: $progress-green;
  orange: $orange;
  darkOrange: $dark-orange;
  blue: $blue;
  darkBlue: $dark-blue;
  cardAccentExperience: $blue;
  actionBlue: $action-blue;
  actionBlueHover: $action-blue-hover;
  stateSealUrl: $state-seal-url;
  stateSealHref: $state-seal-href;
  headerLogoHref: $header-logo-href;

  // Tags
  tagBackgroundColor: $white;
  tagCareer: $light-green;
  tagJob: $light-green;
  tagJobHover: darken($light-green, 4%);
  tagSkill: $tag-skill;
  tagSkillHover: lighten($blue, 12%);
  filterTag: $filter-tag;
  filterTagHover: $filter-tag-hover;

  // Recommendations Card
  cardAccentCareer: $secondary-color-main;
  cardAccentJob: $action-blue;
  cardAccentTraining: #a42639;

  // Chat
  chatTitleAvatar: '/images/favicon.png';
  chatProfileAvatar: '/images/bot-profile.png';
  chatLogo: '/images/bot-profile.png';
}
