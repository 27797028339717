@import './vars.module.scss';

.fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
}
.checksAndRadios {
  padding-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
}
.boxes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
}
.checks {
  display: flex;
  flex-direction: column;
}
.radios {
  display: flex;
  flex-direction: column;
}
.fieldLabel {
  font-size: 14px !important;
  color: $dark-gray;
  font-weight: 400;
}
.fieldLabel__errored {
  color: $error-color-main;
}
.fieldDescription {
  font-size: 10px;
  color: $dark-gray;
  position: relative;
}
.fieldDescriptionSemiClose {
  position: relative;
  top: -9px;
  padding-left: 18px;
  @media screen and (max-width: 899px) {
    top: 0px;
  }
}
.fieldDescriptionClose {
  position: relative;
  top: -12px;
  padding-left: 18px;
}
.helperTextIcon {
  font-size: 16px !important;
  position: absolute;
  top: 53px;
  left: 0px;
  color: $dark-gray;
  @media screen and (max-width: 639px) {
    top: 61px;
  }
}
.helperTextIconClose {
  font-size: 16px !important;
  position: absolute;
  top: 48px;
  left: 0px;
  color: $dark-gray;
  @media screen and (max-width: 639px) {
    top: 51px;
  }
}
.textFieldRoot {
  margin-bottom: 0;
}
.checkBoxRoot {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  &:hover {
    background-color: transparent !important;
  }
}
.labelPadding {
  padding-top: 5px;
  padding-bottom: 9px;
}
.seminarSelect {
  padding-bottom: 4px;
}
.downloadLinks {
  font-size: 14px;
  padding-top: 10px;
  a {
    color: $action-blue;
    &:visited {
      color: $action-blue;
    }
  }
}
.datePicker {
  width: 100%;

  & label,
  & input {
    font-size: 12px;
    font-family: Avenir, Arial, sans-serif;
    color: $dark-gray;
  }
  input {
    padding: 17px 0 9px 11px;
  }
  & label.MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(12px, 2px) scale(0.75);
  }
}
