@import './vars.module.scss';

.loginFlow {
  max-width: 714px;
  margin: 0 auto 56px;
  border-radius: 4px;
  @media screen and (max-width: 671px) {
    max-width: 320px;
  }

  .errorMessage__container {
    margin-bottom: 1rem;
  }
  .errorMessage {
    font-size: 0.8rem;
    color: $error-color-main;
  }

  .submitBtn__container {
    display: flex;
    justify-content: flex-end;
  }
}
.loginFlowTitle {
  font-weight: 500;
  margin-bottom: 1.125rem;
  font-size: 1.275rem;
  color: $dark-gray;
}
.loginFlow__formContainer {
  border-radius: 0;
  background-color: $white;
}
.signInContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 13px 0 40px;
  margin-top: 27px;

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
}
.loadingContainer {
  display: flex;
  justify-content: center;
  padding: 13px 0 40px;
  margin-top: 27px;
}
.loginFlow__form {
  padding: 33px 40px;
}
.loginFlow__form__onboarding {
  padding: 33px 16px;
}
.signInForm {
  border-left: solid 2px $medium-gray;
  padding: 0 33px 33px;
  @media screen and (max-width: 767px) {
    border-left: none;
    padding: 0 16px 0;
  }
}
.signInFormNoStripe {
  padding: 0 33px 33px;
  @media screen and (max-width: 767px) {
    padding: 0 8px 0;
  }
}
.separatorLogin {
  width: 100%;
  border-top: solid 1px $medium-gray;
  border-left: none;
  border-right: none;
  border-bottom: none;
  margin: 0;
  padding-bottom: 33px;
  @media screen and (min-width: 768px) {
    display: none;
  }
}
.infoMessageSubmit__container {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 671px) {
    flex-direction: column;
    justify-content: initial;
  }
}
.infoMessage__container {
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
}

.infoMessage {
  font-size: 0.8rem;
  color: #7f818f;
  padding-bottom: 6px;
}
.infoMessageDark {
  font-size: 0.8rem;
  padding-bottom: 6px;
}
.helperText {
  padding: 0 !important;
  position: absolute;
  top: 100%;
  right: 0;
}
.loadingScreen__cardsContainer {
  padding: 46px 32px 40px;
  @media screen and (max-width: 671px) {
    padding: 46px 16px 40px;
  }
}
.onboardingExperience__cardsContainer {
  padding: 30px 32px 40px;
  @media screen and (max-width: 671px) {
    padding: 46px 16px 40px;
  }
}
.signInButton {
  color: white;
  max-width: 385px;
  text-align: center;
  padding: 15px 0;
  border-radius: 8px;
  font-weight: 700;
  margin: 11px auto 20px;

  &__UI {
    background-color: #47807b;
    &:hover {
      cursor: pointer;
      background-color: darken(#47807b, 8%);
    }
    &:active {
      background-color: darken(#47807b, 12%);
      transition: none;
    }
  }
  &__HI {
    background-color: #4472c4;
    &:hover {
      cursor: pointer;
      background-color: darken(#4472c4, 8%);
    }
    &:active {
      background-color: darken(#4472c4, 12%);
      transition: none;
    }
    @media screen and (max-width: 671px) {
      padding: 10px 0;
    }
  }
}
.textFieldWhite {
  background-color: $white !important;
  border-radius: 3px 3px 0 0 !important;
  input {
    background-color: white;
    border-radius: 4px 4px 0 0;
  }
}
.fullWidthInput {
  div {
    width: 100%;
  }
}
.formError {
  color: $error-color-main;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  position: relative;
  top: -10px;
}
.jobExperienceRemoveButton {
  position: absolute !important;
  right: -48px;
  top: 38px;
  @media screen and (max-width: 767px) {
    top: -40px;
    right: -8px;
  }
}
.jobExperienceItemsSeparator {
  margin-bottom: 32px;
  @media screen and (max-width: 767px) {
    margin-bottom: 48px;
  }
}
.jobExperienceRemoveButtonCloseAbove {
  position: absolute !important;
  top: -40px;
  right: -8px;
}
.jobExperienceItemsSeparatorCloseAbove {
  margin-bottom: 48px;
}
.uiClaimantsTitle {
  font-size: 15px;
  font-weight: 600;
  color: lighten($dark-gray, 8%);
}
