@import './vars.module.scss';

.container {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;
  padding-top: 31px;
  @media screen and (min-width: 672px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 19px 14px;
  background-color: $white;
  border: solid 1px $mid-light-gray;
  min-height: 100px;


  &:hover {
    cursor: pointer;
    border-color: $action-blue;
    h5 {
      color: $action-blue !important;
    }    
  }
}
.active {
  cursor: pointer;
  border-color: $action-blue;
  h5 {
    color: $action-blue !important;
  }
}
.goalsImage {
  padding-bottom: 9px;
}
.moneyImage {
  width: 39px;
}
.trainingImage {
  width: 30px;
}
.briefcaseImage {
  width: 33px;
}